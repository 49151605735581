import { default as _404lZJpwxvtooMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as authI5JGIXywJyMeta } from "/vercel/path0/pages/auth.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: _404lZJpwxvtooMeta?.name ?? "404",
    path: _404lZJpwxvtooMeta?.path ?? "/404",
    meta: _404lZJpwxvtooMeta || {},
    alias: _404lZJpwxvtooMeta?.alias || [],
    redirect: _404lZJpwxvtooMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/404.vue").then(m => m.default || m)
  },
  {
    name: authI5JGIXywJyMeta?.name ?? "auth",
    path: authI5JGIXywJyMeta?.path ?? "/auth",
    meta: authI5JGIXywJyMeta || {},
    alias: authI5JGIXywJyMeta?.alias || [],
    redirect: authI5JGIXywJyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]