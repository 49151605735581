import payload_plugin_Cmd8GcHo9T from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@0.4.0_@firebase+app-types@0.9.0_firebase-admin@11.11.0_firebase-functions@4.4.1__dtk55w2b6bfxbvonv75wdect7u/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_pgP7a2owgI from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.5.1_eslint@8.50.0_rollup@3.29.4_typescript@5.2.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JN1LVyPONp from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.5.1_eslint@8.50.0_rollup@3.29.4_typescript@5.2.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7iI6Cd38sm from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.5.1_eslint@8.50.0_rollup@3.29.4_typescript@5.2.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_wB8rv1HW6y from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.5.1_eslint@8.50.0_rollup@3.29.4_typescript@5.2.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_ixYCRfgBqq from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@3.29.4_typescript@5.2.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_Zc6UqRqlmm from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.5.1_eslint@8.50.0_rollup@3.29.4_typescript@5.2.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import templates_pwa_client_9bd38fb8_XCw3hnLroe from "/vercel/path0/.nuxt/templates.pwa.client.9bd38fb8.ts";
import plugin_client_TcyhCXSVYw from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@0.4.0_@firebase+app-types@0.9.0_firebase-admin@11.11.0_firebase-functions@4.4.1__dtk55w2b6bfxbvonv75wdect7u/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import plugin_client_INtog4BtwY from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@0.4.0_@firebase+app-types@0.9.0_firebase-admin@11.11.0_firebase-functions@4.4.1__dtk55w2b6bfxbvonv75wdect7u/node_modules/nuxt-vuefire/dist/runtime/auth/plugin.client.mjs";
import templates_plugin_dd3ae384_Y1cXSgp6rI from "/vercel/path0/.nuxt/templates.plugin.dd3ae384.ejs";
import chunk_reload_client_Nh7eK0OSFi from "/vercel/path0/node_modules/.pnpm/nuxt@3.7.4_@types+node@20.5.1_eslint@8.50.0_rollup@3.29.4_typescript@5.2.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import firebase_Wjz9XrZo01 from "/vercel/path0/plugins/firebase.ts";
import honeybadger_JREIZARwl8 from "/vercel/path0/plugins/honeybadger.ts";
import lottie_client_MJBevkcINk from "/vercel/path0/plugins/lottie.client.ts";
import useEmitter_8q5Lrsmm6i from "/vercel/path0/plugins/useEmitter.ts";
import veevalidate_components_HZdjzYOWQC from "/vercel/path0/plugins/veevalidate-components.ts";
import veevalidate_rules_Xc2IpxFdQq from "/vercel/path0/plugins/veevalidate-rules.ts";
export default [
  payload_plugin_Cmd8GcHo9T,
  revive_payload_client_pgP7a2owgI,
  unhead_JN1LVyPONp,
  router_7iI6Cd38sm,
  payload_client_wB8rv1HW6y,
  plugin_vue3_ixYCRfgBqq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Zc6UqRqlmm,
  templates_pwa_client_9bd38fb8_XCw3hnLroe,
  plugin_client_TcyhCXSVYw,
  plugin_client_INtog4BtwY,
  templates_plugin_dd3ae384_Y1cXSgp6rI,
  chunk_reload_client_Nh7eK0OSFi,
  firebase_Wjz9XrZo01,
  honeybadger_JREIZARwl8,
  lottie_client_MJBevkcINk,
  useEmitter_8q5Lrsmm6i,
  veevalidate_components_HZdjzYOWQC,
  veevalidate_rules_Xc2IpxFdQq
]