export const appHead = {"meta":[{"name":"viewport","content":"initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Pro Task"}],"link":[{"rel":"icon","type":"image/x-svg","href":"/favicon.svg"}],"style":[],"script":[],"noscript":[],"charset":"utf-8","viewport":"initial-scale=1"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'